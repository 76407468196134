.PageToolsSignals {
  
}
  .PageToolsSignals .Recorder {
    height: 84px;
    padding: 10px;
    margin: 2em auto;
    border-radius: 80px;
    background: #fff;
    box-sizing: content-box;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), inset -1px -1px 0 rgba(255,255,255,0.2);
    background: linear-gradient(#4d4d4d 0%, #3c3c3c 100%);
    border: 1px solid black;
    max-width: 300px;
    
    display: flex;
    align-items: center;
  }
    .PageToolsSignals .Record {
      position: relative;
      display: inline-block;
      width: 80px;
      height: 80px;
      margin-right: 1em;
      
      border: 2px solid #555;
      background: linear-gradient(#545454 0%, #3e3e3e 100%);
      border-radius: 100%;
      
      
      box-shadow: inset -5px -5px 20px rgba(0,0,0,0.05);
      
      cursor: pointer;
    }
    .PageToolsSignals .Record.Recording {
      border-color: #C00;
    }
    .PageToolsSignals .Record:active {
      box-shadow: inset 5px 5px 20px rgba(0,0,0,0.05);    
    }
    .PageToolsSignals .Record:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      
      margin-left: -15px;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      
      border-radius: 100%;
      background: #C00;
      box-shadow: inset 3px 3px 6px rgba(0,0,0,0.1);
      
      transform: rotate(-45deg);
      
      transition: all 0.2s;
    }
    .PageToolsSignals .Record.Recording:before {
      border-radius: 3px;
      transform: rotate(0deg);
      background: #C00;
    }
    .PageToolsSignals .Record:hover:before {
      transform: scale(1.1);
    }
    .PageToolsSignals .Record:before,
    .PageToolsSignals .Record:active:before {
      transform: scale(1);
    }
      .PageToolsSignals .Record input {
        display: none;
      }
    .PageToolsSignals .Record .MuiCircularProgress-colorSecondary {
      color: #C00;
    }
  .PageToolsSignals .Result {
    text-align: center;
  }