.Superseded {
  opacity: 0.5;
}

.HomeyIcon {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border-radius: 100%;
}

.HomeyIconInner {
  background: white;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center center;
  width: 100%;
  height: 100%;
}