.App {
  width: 250px;
  margin-right: 24px;
  margin-bottom: 24px;
}
  .App .AppImage {
    background: url(./img/bg.png) repeat;
  }
  
  .App .New {
    background: orange;
    padding: 2px 8px;
    border-radius: 100px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin: 0 5px;
  }
  
  .App .Author {
    display: block;
  }
  
.AppsWrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}