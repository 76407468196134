.PageToolsDevices .Link {
  color: rgba(  0, 130, 250, 1.00);
  text-decoration: none;
}
.PageToolsDevices .Link:hover {
  text-decoration: underline;
}
.PageToolsDevices .DeviceName {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  font-size: inherit;
  font-weight: inherit;
  padding: 5px 0;
  width: 30vw;
  background: transparent;
  color: #fff;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: all 0.2s;
}
.PageToolsDevices .DeviceName:hover {
  border-color: #fff;
  border-width: 2px;
}
.PageToolsDevices .DeviceName:focus {
  border-color: rgba(  0, 130, 250, 1.00);
  border-width: 2px;
}
