.BuildSummary {

}

  .BuildSummary .Field {
    padding: 5px;
    font-size: 14px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid rgba(255,255,266,0.1);
  }
  .BuildSummary .Field:last-child {
    border-bottom: none;
  }
    .BuildSummary .Field .Label {
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
    }
      .BuildSummary .Field .Label pre {
        display: inline;
      }

    .BuildSummary .Field .Value {
      font-weight: 400;
      font-family: 'Roboto Mono', monospace;
      white-space: pre-wrap;
    }
      .BuildSummary .Field .Value .Color {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
      }

      .BuildSummary .Field .Value .Link {
        color: rgba(  0, 130, 250, 1.00);
      }

    .BuildSummary .Field.Image,
    .BuildSummary .Field.Icon {

    }

      .BuildSummary .Field.Image img,
      .BuildSummary .Field.Icon img {
        display: block;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzdBNURFNzlGOTgwMTFFOThERjk4MkI4NjMzMjgxN0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzdBNURFN0FGOTgwMTFFOThERjk4MkI4NjMzMjgxN0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3N0E1REU3N0Y5ODAxMUU5OERGOTgyQjg2MzMyODE3RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3N0E1REU3OEY5ODAxMUU5OERGOTgyQjg2MzMyODE3RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Po5qfZsAAACASURBVHjajFDBDQAhCAPiRu6/gc7kkTQhiIjHQ4tpS4XHGES01tKTmYEV9N4pq4YL1BOfJWaPAvYvicBTYV8MkUB6Twh53hO8seLCe5tgll6f1tVyzmkJDWxbSswc23ylThzWoK38YduutX1HCq0U9qeyimRhgrLVn/apoPwEGABE5VyEhh7HNwAAAABJRU5ErkJggg==');
      }

  .BuildSummary .DiffAdded {
    background: #74ffa2;
    color: #000;
    border-radius: 2px;
  }

  .BuildSummary .DiffRemoved {
    background: #f1a7ac;
    color: #000;
    border-radius: 2px;
  }

  .BuildSummary .Modified::before {
    content: '!';
    color: red;
    padding: 0 8px;
    font-weight: bolder;
  }