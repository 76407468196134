.PageToolsImages {
}

.PageToolsImages .Highlight:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffff7c;
    animation: fadeOut 2s 1s;
    opacity: 0;
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 0;
    }
}
