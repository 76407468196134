.PageToolsSpeech {
  display: flex;
  flex-direction: column;
  height: 100%;
}
  .PageToolsSpeech .ChatMessages {
    padding: 24px;
    height: 500px;
    overflow-y: auto;
  }
  .PageToolsSpeech .ChatMessage {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 12px;
  }
  .PageToolsSpeech .ChatMessage[author="homey"] {
    flex-direction: row;
  }

    .PageToolsSpeech .ChatMessageAvatar {
      margin-left: 12px;
    }
    .PageToolsSpeech .ChatMessage[author="homey"] .ChatMessageAvatar {
      margin-right: 12px;
      margin-left: 0;
    }

    .PageToolsSpeech .ChatMessage .ChatMessageContent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .PageToolsSpeech .ChatMessage[author="homey"] .ChatMessageContent {
      align-items: flex-start;
    }

    .PageToolsSpeech .ChatMessage .ChatMessageText {
      display: inline-block;
      padding: 8px 12px;
      background: #eee;
      border-radius: 12px;
      border-top-right-radius: 6px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.1);
      margin-bottom: 4px;
            color: #000;
    }
    .PageToolsSpeech .ChatMessage[author="homey"] .ChatMessageText {
      border-top-left-radius: 6px;
      border-top-right-radius: 12px;
      background: rgba(  0, 130, 250, 1.00);
      color: #000;
    }

    .PageToolsSpeech .ChatMessageMeta {
      font-size: 0.8em;
      color: #ccc;
    }
