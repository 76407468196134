.DocsNav {
    position: absolute;
    top: 20px;
    right: 20px;
}

.DocsNav h2 {
    font-size: 24px;
    color: #ffffff;
}

.DocsNav h3 {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
}

.DocsNav h3:first-of-type {
    margin-top: 0;
}

.DocsNav__button {
    border: none;
    display: flex;
    background: #222329;
    box-shadow: 0 10px 44px 0 rgba(0, 0, 0, 0.14),
    0 2px 12px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.08),
    0 0 1px 0 rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    transition: 200ms ease-in-out;
    cursor: pointer;
}

.DocsNav__button-icon {
    -webkit-mask-image: url('./images/grid-view-medium.svg'); /* safari fallback to avoid flicker */
    mask-image: url('./images/grid-view-medium.svg');
    mask-size: 20px;
    mask-repeat: no-repeat;
    mask-position: center;
    display: block;
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    transition: 200ms ease-in-out;
}

.DocsNav__button:hover {
    transform: scale(1.05);
}

.DocsNav__button:active {
    transform: scale(1);
}

.DocsNav__button:hover .DocsNav__button-icon {
}

.DocsNav__menu {
    display: none;
    pointer-events: none;
    position: absolute;
    z-index: 500;
    top: calc(100% + 15px);
    right: 0;
    opacity: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    transform: perspective(300px) rotateX(-5deg) scale(0.95);
    transform-origin: top right;
    background: #222329;
    border-radius: 10px;
    box-shadow: 0 10px 44px 0 rgba(0, 0, 0, 0.14),
    0 2px 12px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.08),
    0 0 1px 0 rgba(255, 255, 255, 0.4);
    padding: 20px;
}

.DocsNav__banner {
    margin: -20px;
    background-image: url(https://etc.athom.com/logo/transparent/1024.png);
    background-position: 47% -22%;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px 10px 0 0;
}

.DocsNav__list {
    list-style: none;
}

.is-DocsNav-active .DocsNav__menu {
    pointer-events: auto;
    display: block;
    opacity: 1;
    transform: perspective(0px) rotateX(0deg) scale(1);
}

.DocsNav__menu::before {
    content: '';
    position: absolute;
    right: 20px;
    top: 0;
    width: 16px;
    height: 16px;
    background-image: linear-gradient(135deg, #0F70F4 50%, rgba(15, 112, 224, 0) 0%);
    border-radius: 4px;
    transform: translateY(-40%) translateX(50%) rotate(45deg);
}

.DocsNav__columns {
    display: flex;
}

.DocsNav__column {
    width: 200px;
}

.DocsNav__column + .DocsNav__column {
    margin-left: 20px;
}


.DocsNav__item a {
    line-height: 30px;
    color: #E7E7E8;
    padding: 2px 10px;
    margin: 0 -10px;
    border-radius: 10px;
    display: block;
    transition: 200ms ease-in-out;
    text-decoration: none;
    font-size: 16px;
}

.DocsNav__item a::after {
    content: '';
    background-color: #E7E7E8;
    mask-image: url('./images/arrow-right.svg');
    mask-size: 12px;
    mask-repeat: no-repeat;
    mask-position: center;
    display: inline-block;
    width: 16px;
    height: 16px;
    opacity: 0;
    transition: 200ms ease-in-out;
    margin-left: 1px;
    transform: translateY(3px);
}

.DocsNav__item a:hover {
    font-weight: 500;
    background: #1E1F24;
    text-decoration: none;
}

.DocsNav__item a:hover::after {
    opacity: 1;
    transform: translateX(5px) translateY(3px);
}
