* {
  margin: 0;
  padding: 0;
  outline: none;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 300;
}

.IconCircle {
  border-radius: 100%;
  padding: 8px;
}

.IconMask {
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  background: #FFF;
}
.IconCircle .IconMask {
  width: 20px;
  height: 20px;
  background: #FFF;
}
.IconMaskWhite {
  background: white;
}

.IconSpin {
  animation: spin 2s infinite linear;
}

p {
  margin-bottom: 1em;
}
p.subtle {
  color: #999;
}
p strong {
  font-weight: 500;
}

p a {
  color: rgba(  0, 130, 250, 1.00);
}

a:visited, a:link {
  color: inherit;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Flex {
  display: flex;
}
.FlexRow {
  flex-direction: row;
}
.FlexColumn {
  flex-direction: column;
}
.FlexGrow {
  flex-grow: 1;
}
.FlexNoGrow {
  flex-grow: 0;
}
.FlexShrink {
  flex-shrink: 1;
}
.FlexNoShrink {
  flex-shrink: 0;
}
.FlexAlignItemsCenter {
  align-items: center;
}
.FlexJustifyContentCenter {
  justify-content: center;
}
.WidthAuto {
  width: auto !important;
}
.Fieldset {
  padding: 1em;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 1em;
}

.RouteNode {
  display: inline-block;
  padding: 0 3px;
  min-width: 20px;
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  margin: 0 3px;
  color: #000;

  transition: all 0.5s;

  cursor: default;
}

.MuiTableRow-root {
  height: 48px;
}

.CapabilityType {
  font-family: monospace !important;
  font-size: 1em !important;
}
.CapabilityType-boolean {
  color: #ff5f00 !important;
}
.CapabilityType-number {
  color: #BD5189 !important;
}
.CapabilityType-string {
  color: #74A53C !important;
}
.CapabilityType-object {
  color: #3c9ca6 !important;
}
.CapabilityType-enum {
  color: #a63c3c !important;
}

.MuiSnackbarContent-message {
  color: #fff;
}

.SimulatorContent .MuiTypography-root {
  color: #000;
}
.SimulatorContent .MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.SimulatorContent .MuiSvgIcon-root {
  fill: #000;
}
