/* Title Divider */
.TitleDivider {
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #88898C;
    text-transform: uppercase;
    font-size: 12px;
    cursor: default;
    transition: all 350ms ease-in-out;
}

.TitleDivider::after {
    content: '';
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    margin-left: 10px;
    flex: 1 1 auto;
    transition: all 350ms ease-in-out;
}

.is-nav-collapsed .TitleDivider {
    padding: 0 5px;
    font-size: 10px;
    justify-content: center;
}

.is-nav-collapsed .TitleDivider::after {
    width: 0;
    flex: 0;
    margin-left: 0;
}

/* NavLogo */
.NavLogo {
    width: 40px;
    height: 40px;
    margin: -4px;
    flex-shrink: 0;
    transition: margin 350ms ease-in-out;
}

.is-nav-collapsed .NavLogo {
    margin: -10px;
}

/* NavAvatar */
.NavAvatar {
    margin-right: 10px;
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    transition: margin 350ms ease-in-out;
}

.NavAvatarTitle {
    overflow: hidden;
}

.NavAvatarTitle h6 {
    overflow: hidden;
    text-overflow: ellipsis;
}

.is-nav-collapsed .NavAvatar {
    margin-top: -8px;
    margin-right: 20px;
    margin-left: -10px;
}

.is-nav-collapsed .NavAvatarTitle {
    display: none;
}

/* LogOutLink */
.LogOutLink {
    --mask-color: #88898C;
    display: flex;
    align-items: center;
    color: #88898c;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 14px;
}

.LogOutLink span {
    margin-right: 5px;
}

.LogOutLink:hover {
    --mask-color: rgba(0, 130, 250, 1.00);
    color: rgba(0, 130, 250, 1.00);
}

/* NavGroup */
.NavGroup {
    padding: 10px;
}

.NavList .NavLink {
    padding-left: 20px;
}

.NavLink {
    border-radius: 10px;
    margin: 5px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: 200ms ease-in-out;
}

.NavLink__icon {
    margin-right: 15px;
}

.NavLink__icon * {
    display: block !important;
}

.NavLink__text {
    font-size: 16px;
    line-height: 20px;
}

.is-nav-collapsed {
    width: 60px !important;
}

.is-nav-collapsed .NavLink__text {
    display: none;
}

.NavLink.Active span {
    color: rgba(0, 130, 250, 1.00);
    font-weight: 500 !important;
}

.NavLink.Active .mask-icon {
    background-color: rgba(0, 130, 250, 1.00);
}

.NavLink:hover {
    background-color: #1E1F24;
    font-weight: 500;
}

.NavLink.Active svg path {
    fill: rgba(0, 130, 250, 1.00);
}

.ReferenceLink {
    display: block;
    margin-top: 20px;
}

.ReferenceLink:hover {
    text-decoration: underline !important;
}


.MuiPaper-rounded {
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 10px 44px 0 rgba(0, 0, 0, 0.14),
    0 2px 12px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.08),
    0 0 1px 0 rgba(255, 255, 255, 0.4) !important;
}

.mask-icon {
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: var(--mask-size, 20px);
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: var(--mask-color, rgba(255, 255, 255, 1));
}

