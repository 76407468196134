.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rotate {
    animation: rotate 2s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}