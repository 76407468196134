.ble-horizontal-container {
  display: flex;
  padding: 20px;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.ble-card {
  max-width: 460px;
  min-width: 400px;
  width: 25%;
  margin-right: 24px;
  overflow-y: scroll !important; /* Sorry */
}

code {
  color: #AAA;
  word-wrap: anywhere;
}

.circularProgress {
  color: rgba(  0, 130, 250, 1.00);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.toast {
  background-color: red;
  padding: 12px 24px;
  border-radius: 4px;
}

.button-wrapper {
  position: relative;
}

.horizontal_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.horizontal_buttons > button {
    width: 100%;
    flex: 1;
    margin-bottom: 12px;
}
