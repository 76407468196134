.AppsHeaderLink {
  color: inherit;
  text-decoration: none;
}
.AppsHeaderLink:hover {
  cursor: pointer;
  text-decoration: underline;
}


.SubmissionComments {
  padding: 2em;
  max-width: 900px;
  max-height: 500px;
  overflow: auto;
  margin: auto;
}

  .SubmissionCommentWrap {
    overflow: hidden;
    padding: 1em;
  }
  
  .SubmissionCommentWrap.Left {
    text-align: left;
  }
  
  .SubmissionCommentWrap.Right {
    text-align: right;
  }
  
    .SubmissionComment {
      display: inline-block;
      text-align: left;
      background: white;
      color: #333;
      padding: 1em;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 400;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      width: 300px;
    } 
     
      .SubmissionComment .AuthorName {
        font-weight: 500;
        margin-bottom: 5px;
      }
      
      .SubmissionComment .Date {
        font-size: 12px;
        opacity: 0.5;
        float: right;
      }
      
      .SubmissionComment .Text {
        white-space: pre-wrap;
      }
  
  .SubmissionComments .ComposeMessage {
    margin-top: 20px;
  }