.App {
  width: 250px;
  margin-right: 24px;
  margin-bottom: 24px;
}
  .App .AppImage {
    background: url(./img/bg.png) repeat;
  }
  
.AppsWrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
  
.CreateApp {
  display: flex;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  height: 268px;
  
  font-size: 30px;
  color: #333;
}