.PageHome {
  padding-top: 10vmin;
  box-sizing: border-box;
}
  .PageHome a[href] {
    text-decoration: none;
  }

.PageHomeAnim {
  overflow: hidden;
  position: absolute;
  left: -50vw;
  top: -35vw;
  bottom: 0;
  right: 0;
}
.PageHomeAnim::before {
  content: "";
  display: block;
  width: 250vw;
  height: 250vw;
  z-index: 0;
  opacity: 0;
  background: url("./logo.svg") no-repeat center center;
  background-size: contain;
  animation: rotate 30s infinite linear, home-fade-in 5s forwards 1 ease-out;
  will-change: transform, opacity;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes home-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}
