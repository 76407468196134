.mg-y-axis .label {
  text-transform: none;
}

a.active {
    color: #fff;
}

.mg-active-datapoint {
    fill: #cfcfcf;
    font-size: 14px;
    font-weight: 300;
}

.mg-barplot .mg-bar-prediction {
    fill: #5b5b5b;
}

.mg-barplot .mg-bar-baseline {
    stroke: #5b5b5b;
}

.mg-baselines line {
    stroke: #676767;
}

.mg-baselines text {
    fill: #fff;
}

.divider {
    color: #f1f1f1;
    opacity: 0.1;
}

.mg-histogram .axis path,
.mg-histogram .axis line {
    opacity: 0.5;
    stroke: #ccc;
}

.mg-histogram .mg-bar rect {
    fill: #d8b307;
}

tspan.hist-symbol {
    fill: #d8b307;
}

.mg-least-squares-line {
    stroke: #f57070;
}

.mg-category-guides line {
    stroke: #676767;
}

.mg-main-area-solid svg .mg-main-area {
    fill: #52491f;
}

.mg-markers line {
    stroke: #676767;
}

.mg-markers text {
    fill: #fff;
}

circle.mg-points-mono {
    fill-opacity: 0.6;
    stroke: #a89641;
    fill: #a89641;
    stroke-opacity: 1;
}

tspan.mg-points-mono {
    fill: #a89641;
    stroke: #a89641;
}

circle.mg-points-mono.selected {
    fill: #a89641;
    fill-opacity: 1;
    stroke-opacity: 1;
}

text {
    fill: #fff;
}

.mg-x-rug-mono,
.mg-y-rug-mono {
    stroke: #fff;
}

.mg-x-axis line,
.mg-y-axis line {
    stroke: #676767;
}

.mg-x-axis text,
.mg-y-axis text,
.mg-histogram .axis text {
    font-size: 11px;
    fill: #fff;
}

.mg-year-marker text {
    fill: #fff;
}

.mg-year-marker line {
    stroke: #676767;
}

.mg-line1-color {
    stroke: rgba(  0, 130, 250, 1.00);
}

.mg-hover-line1-color,
.mg-area1-color,
.mg-line1-legend-color {
    stroke: rgba(  0, 130, 250, 1.00);
    fill: rgba(  0, 130, 250, 1.00);
}

.mg-line1-legend-color {
    stroke: rgba(  0, 130, 250, 1.00);
    color: rgba(  0, 130, 250, 1.00);
}
