.Simulator {
  position: relative;
  border: 1px solid #aaa;
  border-radius: 15px;
  box-shadow: 0 5px 30px rgba(0,0,0,0.1), inset 50px 50px 100px rgba(0,0,0,0.025);
  background: #333;

  display: inline-block;
}
  .Simulator .SimulatorSpeaker {
    height: 3px;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 3px;
    width: 30%;
    margin: 20px auto;
    background: rgba(255,255,255,0.1);
  }
  .Simulator .SimulatorHomeButton {
    margin: 10px auto;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(255,255,255,0.2);
    box-shadow: inset 10px 10px 20px rgba(0,0,0,0.05);
  }
  .Simulator .SimulatorInner {
    position: relative;
    overflow: hidden;
    margin: 0 15px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
    .Simulator .SimulatorHeader {
      display: grid;
      grid-auto-flow: column;
      flex-shrink: 0;
      justify-content: left;
      align-items: center;
      margin-top: 1vh;
    }

    .Simulator .SimulatorAppTitle {
      color: rgba(0,0,0,0.6);
      font-size: 1.4em;
      font-weight: 500;
    }

    .Simulator .SimulatorBack {
      padding: 0 10px;
      display: flex;
      align-items: center;
    }

      .Simulator .SimulatorBack .Icon {
        color: rgba(0,0,0,0.6);
        font-size: 2.4em;
        cursor: pointer;
      }

    .Simulator .SimulatorTitle {
      padding: 0 2vh;
      margin-top: 1vh;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }

      .Simulator .SimulatorTitle .Text {
        text-align: center;
        flex-grow: 1;
        color: rgba(0,0,0,0.6);
        font-size: 1.4em;
        font-weight: 500;
      }
    .Simulator .SimulatorIframeWrap,
    .Simulator .SimulatorContent {
      position: relative;
      height: 100%;
      background: white;
      overflow: hidden;
      box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    }
    .Simulator .SimulatorIframe {
      display: block;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      border: none;
      margin: 10px;
    }

    .Simulator .SimulatorIframe.is-seamless {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    
    .Simulator .SimulatorContent {
      display: block;
      flex-grow: 1;
      border: none;
      overflow: auto;
      height: 100%;
    }
    .Simulator .SimulatorIframeWrap,
    .Simulator .SimulatorIframeWrap.Active + .SimulatorContent {
      display: none;
    }
    .Simulator .SimulatorIframeWrap.Active {
      display: block;
    }
    .Simulator .SimulatorIframeWrap:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 9;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: white url(throbber.gif) no-repeat center center;

      opacity: 0.85;
      visibility: visible;

      transition: all 0.3s;
    }
    .Simulator.Ready .SimulatorIframeWrap:before {
      opacity: 0;
      visibility: hidden;
    }
