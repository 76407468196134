.PageToolsLEDRing {

}

.PageToolsLEDRing .Ledring {
    position: relative;
    width: 500px;
    height: 500px;
    background: black;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.PageToolsLEDRing .Ledring .Led {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    border: 1px solid #111;
}

.PageToolsLEDRing .Ledring .Info {
    color: white;
    text-align: left;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.2em;
    width: 65%;
}
